import React, { useState } from 'react';
import {
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import { Image } from 'expo-image';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';
import { areaRepository } from '../../../Domain/Repositories/AreaRepository';
import Icons from '../../../Assets/Icons';

// Add 10% to the text layout so it doesn't pre-shift the
// labels to the bottom.
const TextLayoutFactor = 1.1;

interface AreaListItemProps
{
  /**
   * The maximum item width.
   */
  maxWidth?: number;

  /**
   * The screen count container width.
   */
  screenCountContainerWidth?: number;

  /**
   * The item separation distance in px.
   */
  itemSeparation?: number;

  /**
   * The area id.
   */
  areaId: string;

  /**
   * Callback when the area was selected.
   * @param id The selected id.
   */
  onAreaSelected?: (id: string) => void;

  /**
   * Is selected.
   */
  isSelected?: boolean;

  /**
   * Callback when the edit area was selected.
   * @param id The selected id.
   */
  onEditAreaSelected?: (id: string) => void;
}

/**
 * Renders the Area List Item.
 */
export default function AreaListItem({
  maxWidth = RFValue(150),
  screenCountContainerWidth = RFValue(25),
  itemSeparation = RFValue(5),
  areaId,
  onAreaSelected,
  isSelected = false,
  onEditAreaSelected,
}: AreaListItemProps): JSX.Element
{
  const [width, setWidth] = useState(maxWidth);

  const styles = StyleSheet.create({
    container: {
      width,
      height: RFValue(30),
      marginRight: itemSeparation,
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: RFValue(1),
      borderColor: 'white',
    },
    button: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    countLabelContainer: {
      width: screenCountContainerWidth,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.BLACK(0.5),
      borderRightWidth: RFValue(1),
      borderRightColor: 'white',
    },
    countLabel: {
      fontSize: RFValue(10),
      lineHeight: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      width: '90%',
      textAlign: 'center',
    },
    labelContainer: {
      flex: 3,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    label: {
      fontSize: RFValue(10),
      lineHeight: RFValue(9),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      textAlign: 'left',
      paddingHorizontal: RFValue(8),
      maxWidth,
    },
    areaSettingsContainer: {
      width: screenCountContainerWidth,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.APP.red,
      borderLeftWidth: RFValue(1),
      borderLeftColor: 'white',
    },
    areaSettingsButton: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    areaSettingsIcon: {
      width: '50%',
      aspectRatio: 1,
    },
  });

  const getAreaName = (): string =>
  {
    const area = areaRepository.getById(areaId);
    return area?.name ?? '';
  };

  const getScreenCount = (): string =>
  {
    const area = areaRepository.getById(areaId);
    if (area === undefined)
    {
      return '0';
    }

    const { length } = area.screens;
    return length < 99 ? length.toString() : '99+';
  };

  const onLayout = (event: LayoutChangeEvent): void =>
  {
    setWidth((event.nativeEvent.layout.width * TextLayoutFactor) + (screenCountContainerWidth * 2));
  };

  return useObserver(() => (
    <View style={[styles.container, {
      width,
    }]}
    >
      <TouchableOpacity
        style={styles.button}
        onPress={(): void => onAreaSelected?.(areaId)}
      >
        <View style={styles.countLabelContainer}>
          <Text
            numberOfLines={1}
            style={styles.countLabel}
          >
            {getScreenCount()}
          </Text>
        </View>

        <View style={[styles.labelContainer, {
          backgroundColor: isSelected ? Colors.APP.teal : Colors.BLACK(0.3),
        }]}
        >
          <Text
            style={styles.label}
            onLayout={onLayout}
          >
            {getAreaName()}
          </Text>
        </View>

        <View style={styles.areaSettingsContainer}>
          <TouchableOpacity
            style={styles.areaSettingsButton}
            onPress={(): void => onEditAreaSelected?.(areaId)}
          >
            <Image
              style={styles.areaSettingsIcon}
              contentFit="contain"
              source={Icons.Settings}
              responsivePolicy="initial"
            />
          </TouchableOpacity>
        </View>
      </TouchableOpacity>
    </View>
  ));
}
