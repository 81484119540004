import { decorate, observable } from 'mobx';
import PartyResponse from '../Models/Responses/PartyResponse';

/**
 * Party Repository.
 */
export class PartyRepository
{
  /**
   * All Parties.
   */
  public all: PartyResponse[] = [];

  /**
   * Adds or updates the party list.
   * @param party The party.
   */
  public addOrUpdateParty(party: PartyResponse): void
  {
    const index = this.all.findIndex((x) => x.id === party.id);

    if (index === -1)
    {
      this.all.push(party);
      return;
    }

    this.all[index] = party;
  }

  /**
   * Clear all.
   */
  public clearAll(): void
  {
    this.all.length = 0;
  }
}

decorate(PartyRepository, {
  all: observable,
});

export const partyRepository = new PartyRepository();
