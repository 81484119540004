import { getEnvironment } from '../../Config/Environment';
import { ThirtyOneError } from '../Error/ThirtyOneError';
import Logger from '../Logger/Logger';
import { PromiseFail, PromiseResponse, PromiseSuccess } from '../Models/PromiseResponse';
import PartyResponse from '../Models/Responses/PartyResponse';
import { partyRepository } from '../Repositories/PartyRepository';
import ApiService from './ApiService';

const logger = Logger.Create('PartyService');

/**
 * Handles party operations.
 */
export default class PartyService
{
  /**
   * Get all available parties.
   * @returns Promise.
   */
  public static async getAll(): Promise<PromiseResponse<PartyResponse[]>>
  {
    const url = `${getEnvironment().serverApiBase}/api/Party`;

    return ApiService.get<PartyResponse[]>(url, true)
      .then((parties: PartyResponse[]) =>
      {
        partyRepository.all = parties;
        return PromiseSuccess(parties);
      })
      .catch((error: ThirtyOneError) =>
      {
        logger.error(error.message);
        return PromiseFail(error.message);
      });
  }
}
