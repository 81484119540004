/**
 * The available screen types.
 */
enum ScreenTypes
{
  Login = '',
  ResetPassword = 'ResetPassword',
  UnassignedScreens = 'UnassignedScreens',
  VenueAreas = 'VenueAreas',
  SetChannel = 'SetChannel',
  EditChannel = 'EditChannel',
  ScreenConfiguration = 'ScreenConfiguration',
}

export default ScreenTypes;
