import React, { useMemo, useState } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { useObserver } from 'mobx-react-lite';
import { RFPercentage, RFValue } from 'react-native-responsive-fontsize';
import { LinearGradient } from 'expo-linear-gradient';
import { Dropdown } from 'react-native-element-dropdown';
import { Image } from 'expo-image';
import { isEmpty, isNull, isUndefined } from 'lodash';
import Fonts from '../../../../Domain/Types/Fonts';
import { responsiveScreenRepository } from '../../../../Domain/Repositories/ResponsiveScreenRepository';
import ModalViewBase from '../ModalViewBase';
import ModalType from '../../../../Domain/Types/ModalType';
import { Colors } from '../../../../Config/Colors';
import { BaseModalType, modalController } from '../ModalController';
import INotificationModalData from '../../../../Domain/Models/INotificationModalData';
import DropdownModel from '../../../../Domain/Models/DropdownModel';
import Icons from '../../../../Assets/Icons';
import IConfirmationDialogModel from '../../../../Domain/Models/IConfirmationDialogModel';
import PartyService from '../../../../Domain/Services/PartyService';
import { partyRepository } from '../../../../Domain/Repositories/PartyRepository';
import ManageScreenPartyModalData from '../../../../Domain/Models/ManageScreenPartyModalData';
import ScreenService from '../../../../Domain/Services/ScreenService';

const MAXIMUM_ITEMS_PER_VIEW = 5;
const ITEM_HEIGHT = RFValue(25);
const MAXIMUM_HEIGHT = MAXIMUM_ITEMS_PER_VIEW * ITEM_HEIGHT;

/**
 * The Manage Screen Party Modal.
 * @returns JSX.Element.
 */
export default function ManageScreenPartyModal(): JSX.Element
{
  const [loading, setLoading] = useState(false);
  const [selectedParty, setSelectedParty] = useState<DropdownModel | null>(null);

  const closeModal = (): void =>
  {
    modalController.hide();
  };

  const onSetPartyPressed = async (): Promise<void> =>
  {
    const data = modalController.activeModal.data as ManageScreenPartyModalData;
    const { screenId } = data;

    if (isUndefined(data) || isUndefined(screenId) || isNull(screenId) || isEmpty(screenId))
    {
      showFailedModal('No screen selected');
      return;
    }

    if (isEmpty(selectedParty) || isNull(selectedParty))
    {
      showFailedModal('No party selected');
      return;
    }

    const notification: IConfirmationDialogModel = {
      title: 'Info',
      message: 'Are you sure you want to set the party for this screen?',
      negativeAction: (): void => modalController.showPrevious(),
      positiveAction: async (): Promise<void> =>
      {
        const getScreenResponse = await ScreenService.get(screenId);
        const getScreenResponseData = getScreenResponse.data;

        if (!getScreenResponse.success || isUndefined(getScreenResponseData))
        {
          showFailedModal(getScreenResponse.reason);
          return;
        }

        const { areaId, channelConfig } = getScreenResponseData;
        const response = await ScreenService.setScreenParty({
          id: screenId,
          areaId,
          channelConfig,
          partyId: selectedParty.value,
        });

        if (response.success)
        {
          showSuccessModal('Succesfully set the party!', (): void => modalController.hide());
          return;
        }

        showFailedModal(response.reason);
      },
    };

    modalController.show({
      modalType: BaseModalType.GenericConfirmation,
      data: notification,
    });
  };

  const onClearPartyPressed = async (): Promise<void> =>
  {
    const data = modalController.activeModal.data as ManageScreenPartyModalData;
    const { screenId } = data;

    if (isUndefined(data) || isUndefined(screenId) || isNull(screenId) || isEmpty(screenId))
    {
      showFailedModal('No screen selected');
      return;
    }

    const notification: IConfirmationDialogModel = {
      title: 'Info',
      message: 'Are you sure you want to clear the party for this screen?',
      negativeAction: (): void => modalController.showPrevious(),
      positiveAction: async (): Promise<void> =>
      {
        const getScreenResponse = await ScreenService.get(screenId);
        const getScreenResponseData = getScreenResponse.data;

        if (!getScreenResponse.success || isUndefined(getScreenResponseData))
        {
          showFailedModal(getScreenResponse.reason);
          return;
        }

        const { areaId, channelConfig } = getScreenResponseData;
        const response = await ScreenService.setScreenParty({
          id: screenId,
          areaId,
          channelConfig,
          partyId: null,
        });

        if (response.success)
        {
          showSuccessModal('Succesfully cleared the party for this screen!', (): void => modalController.hide());
          setSelectedParty(null);
          return;
        }

        showFailedModal(response.reason);
      },
    };

    modalController.show({
      modalType: BaseModalType.GenericConfirmation,
      data: notification,
    });
  };

  const onRefreshSelected = async (): Promise<void> =>
  {
    const notification: INotificationModalData = {
      message: 'Retrieving parties...',
    };

    modalController.show({
      modalType: BaseModalType.NonDismissableNotification,
      data: notification,
    });

    const response = await PartyService.getAll();
    modalController.hide();

    if (response.success)
    {
      showSuccessModal('Successfully retrieved parties!', (): void => modalController.showPrevious());
      return;
    }

    showFailedModal(response.reason);
  };

  const showSuccessModal = (
    message: string,
    onConfirmed = (): void => modalController.showPrevious(),
  ): void =>
  {
    const notification: INotificationModalData = {
      title: 'Info',
      message,
      disableCloseOnConfirm: true,
      onConfirmButtonPressed: onConfirmed,
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  const showFailedModal = (reason: string): void =>
  {
    const notification: INotificationModalData = {
      title: 'Error!',
      message: reason,
      disableCloseOnConfirm: true,
      onConfirmButtonPressed: (): void =>
      {
        modalController.showPrevious();
      },
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  const reset = (): void =>
  {
    setSelectedParty(null);
    setLoading(false);
  };

  const formatText = (input: string): string => (input.length > 15 ? `${input.slice(0, 15)}...` : input);

  const constructDropdown = (): DropdownModel[] =>
  {
    const { all } = partyRepository;

    const dropdownItems: DropdownModel[] = all.map((x) => ({
      label: formatText(x.name),
      value: x.id,
    }));

    return dropdownItems;
  };

  const onChange = (item: DropdownModel): void =>
  {
    setSelectedParty(item);
  };

  const styles = useMemo(() => StyleSheet.create({
    content: {
      height: RFPercentage(24),
      width: undefined,
      aspectRatio: 2,
      backgroundColor: Colors.PRIMARY(),
      shadowColor: Colors.APP.teal,
      shadowOffset: {
        width: RFValue(1.1),
        height: RFValue(1.1),
      },
      shadowOpacity: 1,
      shadowRadius: RFValue(0.5),
      zIndex: 10,
    },
    container: {
      height: '100%',
      width: '100%',
    },
    titleButton: {
      width: '100%',
      aspectRatio: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleButtonIcon: {
      width: '40%',
      aspectRatio: 1,
    },
    refreshContainer: {
      height: '100%',
      aspectRatio: 1,
      position: 'absolute',
      right: 0,
    },
    titleView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    titleText: {
      textAlign: 'left',
      fontSize: RFValue(10),
      letterSpacing: RFValue(0.6),
      color: 'white',
      fontFamily: Fonts.JudgeMedium,
    },
    nameInputView: {
      flex: 0.6,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    dropdown: {
      backgroundColor: Colors.DROPDOWN.unselected,
      width: '50%',
      height: '10%',
      padding: '5%',
      borderColor: Colors.DROPDOWN.border,
      borderWidth: RFValue(1),
    },
    dropdownPlaceholderText: {
      textAlign: 'center',
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      width: '70%',
      color: 'white',
    },
    dropdownSelectedText: {
      textAlign: 'center',
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      marginLeft: '5%',
    },
    dropdownListContainer: {
      backgroundColor: Colors.DROPDOWN.unselected,
      overflow: 'hidden',
      borderColor: Colors.DROPDOWN.border,
      borderWidth: RFValue(1),
    },
    dropdownListItemContainer: {
      height: ITEM_HEIGHT,
      justifyContent: 'center',
    },
    dropdownListItemText: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      textAlign: 'center',
      justifyContent: 'center',
      color: 'white',
    },
    dropdownArrowIcon: {
      width: RFValue(10),
      height: undefined,
      aspectRatio: 1,
      position: 'absolute',
      right: 0,
    },
    closeView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row-reverse',
    },
    button: {
      height: '70%',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '5%',
      marginHorizontal: '2%',
    },
    buttonContent: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonTextView: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonText: {
      paddingHorizontal: RFValue(20),
      textAlign: 'center',
      fontFamily: Fonts.JudgeMedium,
      fontSize: RFValue(10),
      color: 'white',
    },
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <ModalViewBase
      onModalHidden={reset}
      modalType={ModalType.ManageScreenPartyModal}
      closeModalOnBackgroundTouched={false}
    >
      <View style={styles.content}>
        <View style={styles.container}>
          <View style={styles.titleView}>
            <View style={styles.refreshContainer}>
              <TouchableOpacity
                style={styles.titleButton}
                onPress={onRefreshSelected}
              >
                <Image
                  source={Icons.Refresh}
                  style={styles.titleButtonIcon}
                  contentFit="contain"
                  responsivePolicy="initial"
                />
              </TouchableOpacity>
            </View>

            <Text style={styles.titleText}>
              Manage Screen Party
            </Text>
          </View>

          <View style={styles.nameInputView}>
            <Dropdown
              placeholder="Assign a party"
              style={[styles.dropdown, {
                opacity: loading ? 0.2 : 1,
              }]}
              placeholderStyle={styles.dropdownPlaceholderText}
              selectedTextStyle={styles.dropdownSelectedText}
              containerStyle={styles.dropdownListContainer}
              itemContainerStyle={styles.dropdownListItemContainer}
              itemTextStyle={styles.dropdownListItemText}
              data={constructDropdown()}
              maxHeight={MAXIMUM_HEIGHT}
              labelField="label"
              valueField="value"
              value={selectedParty}
              activeColor={Colors.DROPDOWN.selected}
              iconStyle={styles.dropdownArrowIcon}
              iconColor={Colors.DROPDOWN.icon}
              onChange={onChange}
              disable={loading}
            />
          </View>

          {!loading ? (
            <View style={styles.closeView}>
              <TouchableOpacity
                style={styles.button}
                disabled={selectedParty === null}
                onPress={onSetPartyPressed}
              >
                <LinearGradient
                  colors={selectedParty === null
                    ? Colors.LOGIN().inactive : Colors.LOGIN().active}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Set Party
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button}
                onPress={onClearPartyPressed}
              >
                <LinearGradient
                  colors={Colors.LOGIN().active}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Clear Party
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>
              <TouchableOpacity
                style={styles.button}
                onPress={closeModal}
              >
                <LinearGradient
                  colors={Colors.LOGIN().active}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Cancel
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={styles.closeView}>
              <ActivityIndicator color={Colors.APP.teal} size="large" />
            </View>
          )}
        </View>
      </View>
    </ModalViewBase>
  ));
}
