import React, { useEffect, useState } from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import {
  isEmpty,
  isEqual,
  isNull,
  isUndefined,
} from 'lodash';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';
import { screenRepository } from '../../../Domain/Repositories/ScreenRepository';
import { modalController, BaseModalType } from '../../Shared/Modals/ModalController';
import Logger from '../../../Domain/Logger/Logger';
import Utils from '../../../Domain/Utils/Utils';
import IConfirmationDialogModel from '../../../Domain/Models/IConfirmationDialogModel';
import INotificationModalData from '../../../Domain/Models/INotificationModalData';
import ScreenService from '../../../Domain/Services/ScreenService';

const logger = Logger.Create('ConfigurationTextEditor');

interface ConfigurationTextEditorProps
{
  /**
   * The screen id.
   */
  screenId: string;
}

/**
 * Renders the Screen Configuration Screen.
 */
export default function ConfigurationTextEditor({
  screenId = '',
}: ConfigurationTextEditorProps): JSX.Element
{
  const [config, setConfig] = useState('');

  useEffect(() =>
  {
    const screen = screenRepository.getById(screenId);
    if (isUndefined(screen))
    {
      setConfig('');
      return;
    }

    const { channelConfig } = screen;
    if (isNull(channelConfig) || isEmpty(channelConfig))
    {
      setConfig('');
      return;
    }

    if (!Utils.validateJson(channelConfig))
    {
      logger.fatal(`The channel config from the server is invalid! ${channelConfig}`);
      setConfig('');
      return;
    }

    const json = Utils.prettifyJson(channelConfig);
    setConfig(json);
  }, [screenId]);

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
    },
    textInputContainer: {
      width: '100%',
      flex: 1,
    },
    textValidationBorder: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      borderWidth: RFValue(1),
      borderColor: Colors.APP.red,
    },
    input: {
      height: '100%',
      width: '100%',
      backgroundColor: Colors.BLACK(0.15),
      color: 'white',
      fontSize: RFValue(10),
      lineHeight: RFValue(16),
      fontFamily: Fonts.JudgeMedium,
      textAlign: 'left',
      paddingTop: RFValue(10),
      paddingRight: RFValue(10),
      paddingBottom: RFValue(20),
      paddingLeft: RFValue(6),
      letterSpacing: RFValue(0.8),
    },
    optionsContainer: {
      width: '100%',
      height: RFValue(30),
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    optionsContentContainer: {
      height: '100%',
    },
    button: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    buttonLabelContainer: {
      height: RFValue(16),
      justifyContent: 'center',
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.teal,
    },
    buttonLabel: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      letterSpacing: RFValue(0.8),
      paddingLeft: RFValue(6),
      paddingRight: RFValue(10),
    },
  });

  const getPlaceholder = (): string | undefined =>
  {
    if (isEmpty(screenId))
    {
      return 'No screen selected';
    }

    const screen = screenRepository.getById(screenId);
    if (isUndefined(screen))
    {
      return 'Failed to retrieve any information with this selected screen.';
    }

    const { channelConfig } = screen;
    if (isNull(channelConfig) || isEmpty(channelConfig))
    {
      return 'No configuration found for this screen.';
    }

    return undefined;
  };

  const undoChanges = (): void =>
  {
    const screen = screenRepository.getById(screenId);
    if (isUndefined(screen))
    {
      return;
    }

    const { channelConfig } = screen;
    if (isNull(channelConfig) || isEmpty(channelConfig))
    {
      return;
    }

    const prettyJson = Utils.prettifyJson(channelConfig);
    setConfig(prettyJson);
  };

  const onSave = (): void =>
  {
    if (isNull(screenId) || isEmpty(screenId))
    {
      showFailedModal('No screen selected');
      return;
    }

    if (!Utils.validateJson(config))
    {
      modalController.show({
        modalType: BaseModalType.SimpleNotification,
        data: {
          title: 'Error!',
          message: 'The JSON object is invalid!',
          disableCloseOnConfirm: true,
          onConfirmButtonPressed: (): void => modalController.hide(),
        },
      });
      return;
    }

    const notification: IConfirmationDialogModel = {
      title: 'Info',
      message: 'Are you sure you want to update this configuration?',
      negativeAction: (): void => modalController.hide(),
      positiveAction: async (): Promise<void> =>
      {
        const getScreenResponse = await ScreenService.get(screenId);
        const getScreenResponseData = getScreenResponse.data;

        if (!getScreenResponse.success || isUndefined(getScreenResponseData))
        {
          showFailedModal(getScreenResponse.reason);
          return;
        }

        const { areaId, partyId } = getScreenResponseData;
        const response = await ScreenService.setScreenParty({
          id: screenId,
          areaId,
          channelConfig: config,
          partyId,
        });

        if (response.success)
        {
          showSuccessModal('Configuration updated successfully!', (): void => modalController.hide());
          return;
        }

        showFailedModal(response.reason);
      },
    };

    modalController.show({
      modalType: BaseModalType.GenericConfirmation,
      data: notification,
    });
  };

  const showSuccessModal = (
    message: string,
    onConfirmed = (): void => modalController.showPrevious(),
  ): void =>
  {
    const notification: INotificationModalData = {
      title: 'Info',
      message,
      disableCloseOnConfirm: true,
      onConfirmButtonPressed: onConfirmed,
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  const showFailedModal = (reason: string): void =>
  {
    const notification: INotificationModalData = {
      title: 'Error!',
      message: reason,
      disableCloseOnConfirm: true,
      onConfirmButtonPressed: (): void =>
      {
        modalController.showPrevious();
      },
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  const getValidationOpacity = (): number =>
  {
    if (isEmpty(config))
    {
      return 0;
    }

    return Utils.validateJson(config) ? 0 : 1;
  };

  return useObserver(() => (
    <View style={[styles.container]}>
      <View style={styles.textInputContainer}>
        <View
          pointerEvents="none"
          style={[styles.textValidationBorder, {
            opacity: getValidationOpacity(),
          }]}
        />

        <TextInput
          style={styles.input}
          placeholder={getPlaceholder()}
          autoCompleteType="off"
          placeholderTextColor="white"
          value={config}
          onChangeText={setConfig}
          multiline
        />
      </View>

      <View style={styles.optionsContainer}>
        <View style={styles.optionsContentContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={undoChanges}
          >
            <View style={styles.buttonLabelContainer}>
              <Text style={styles.buttonLabel}>
                Undo All
              </Text>
            </View>
          </TouchableOpacity>
        </View>

        <View style={styles.optionsContentContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={onSave}
          >
            <View style={styles.buttonLabelContainer}>
              <Text style={styles.buttonLabel}>
                Save Changes
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  ));
}
