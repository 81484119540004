import React from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';
import { screenRepository } from '../../../Domain/Repositories/ScreenRepository';

interface UnassignedScreenListItemProps
{
  /**
   * The item height.
   */
  itemHeight?: number;

  /**
   * The screen id.
   */
  screenId: string;

  /**
   * Callback when the Select Area was pressed.
   * @param id The selected id.
   */
  onSelectAreaPressed?: (id: string) => void;

  /**
   * Item index.
   */
  index?: number;
}

/**
 * Renders the Unassigned Screen List Item.
 */
export default function UnassignedScreensListItem({
  itemHeight = RFValue(50),
  screenId,
  onSelectAreaPressed,
  index = 0,
}: UnassignedScreenListItemProps): JSX.Element
{
  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: itemHeight,
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: index % 2 ? undefined : Colors.BLACK(0.2),
    },
    contentContainer: {
      width: '95%',
      height: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    labelContainer: {
      flex: 0.7,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    label: {
      width: '100%',
      fontSize: RFValue(10),
      letterSpacing: RFValue(0.5),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      paddingLeft: RFValue(6),
    },
    screenNameLabel: {
      fontSize: RFValue(10),
      letterSpacing: RFValue(0.5),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      paddingLeft: RFValue(5),
    },
    buttonContainer: {
      flex: 0.3,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    button: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: RFValue(11),
    },
    buttonLabelContainer: {
      height: RFValue(16),
      justifyContent: 'center',
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.teal,
    },
    buttonLabel: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      letterSpacing: RFValue(0.8),
      paddingLeft: RFValue(6),
    },
  });

  const getScreenName = (): string =>
  {
    const screen = screenRepository.getById(screenId);
    return screen?.name ?? '';
  };

  const selectAreaPressed = (): void =>
  {
    if (onSelectAreaPressed !== undefined)
    {
      onSelectAreaPressed(screenId);
    }
  };

  return useObserver(() => (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <View style={styles.labelContainer}>
          <Text style={styles.label}>
            {`${index + 1}.`}
            <Text style={styles.screenNameLabel}>
              {getScreenName()}
            </Text>
          </Text>
        </View>
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={styles.button}
            onPress={selectAreaPressed}
          >
            <View style={styles.buttonLabelContainer}>
              <Text style={styles.buttonLabel}>
                Set Area
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  ));
}
