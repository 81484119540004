/**
 * The Modal Types.
 */
enum ModalType
{
  CreateAreaModal = 'CreateAreaModal',
  AssignScreenAreaModal = 'AssignScreenAreaModal',
  EditAreaModal = 'SetPartyModal',
  ManageScreenPartyModal = 'ManageScreenPartyModal',
  LinkManagerModal = 'LinkManagerModal',
}

export default ModalType;
