import React from 'react';
import {
  StyleSheet, Text, TouchableOpacity, View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';
import { screenRepository } from '../../../Domain/Repositories/ScreenRepository';

interface AreaScreenListItemProps
{
  /**
   * The item height.
   */
  itemHeight?: number;

  /**
   * The screen id.
   */
  screenId: string;

  /**
   * Callback when the control screen was pressed.
   */
  onControlAreaScreenPressed?: () => void;

  /**
   * Callback when the refresh screen was pressed.
   */
  onRefreshScreenPressed?: () => void;

  /**
   * Callback when the configure screen was pressed.
   */
  onConfigureScreenPressed?: () => void;

  /**
   * Callback when the set party was pressed.
   */
  onSetPartyPressed?: () => void;

  /**
   * Item index.
   */
  index?: number;
}

/**
 * Renders the Screen List Item.
 */
export default function AreaScreenListItem({
  itemHeight = RFValue(60),
  screenId,
  onControlAreaScreenPressed,
  onRefreshScreenPressed,
  onConfigureScreenPressed,
  onSetPartyPressed,
  index = 0,
}: AreaScreenListItemProps): JSX.Element
{
  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: itemHeight,
      backgroundColor: index % 2 ? Colors.BLACK(0.2) : undefined,
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentContainer: {
      width: '95%',
      height: '80%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    labelContainer: {
      flex: 1,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    labelContentContainer: {
      height: '100%',
      width: '99%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    label: {
      width: '100%',
      fontSize: RFValue(10),
      letterSpacing: RFValue(0.5),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
    },
    screenNameLabel: {
      fontSize: RFValue(10),
      letterSpacing: RFValue(0.5),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      paddingLeft: RFValue(5),
    },
    buttonContainer: {
      height: RFValue(25),
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'row',
    },
    buttonContentContainer: {
      height: '100%',
    },
    button: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    buttonLabelContainer: {
      height: RFValue(16),
      justifyContent: 'center',
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.red,
    },
    buttonLabel: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      letterSpacing: RFValue(0.8),
      paddingLeft: RFValue(6),
      paddingRight: RFValue(10),
    },
  });

  const getScreenName = (): string =>
  {
    const screen = screenRepository.getById(screenId);
    return screen?.name ?? '';
  };

  return useObserver(() => (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <View style={styles.labelContainer}>
          <View style={styles.labelContentContainer}>
            <Text style={styles.label}>
              {`${index + 1}.`}
              <Text style={styles.screenNameLabel}>
                {getScreenName()}
              </Text>
            </Text>
          </View>
        </View>

        <View style={styles.buttonContainer}>
          <View style={styles.buttonContentContainer}>
            <TouchableOpacity
              style={styles.button}
              onPress={(): void => onSetPartyPressed?.()}
            >
              <View style={styles.buttonLabelContainer}>
                <Text style={styles.buttonLabel}>
                  Set Party
                </Text>
              </View>
            </TouchableOpacity>
          </View>

          <View style={styles.buttonContentContainer}>
            <TouchableOpacity
              style={styles.button}
              onPress={(): void => onControlAreaScreenPressed?.()}
            >
              <View style={styles.buttonLabelContainer}>
                <Text style={styles.buttonLabel}>
                  Set Channel
                </Text>
              </View>
            </TouchableOpacity>
          </View>

          <View style={styles.buttonContentContainer}>
            <TouchableOpacity
              style={styles.button}
              onPress={(): void => onConfigureScreenPressed?.()}
            >
              <View style={styles.buttonLabelContainer}>
                <Text style={styles.buttonLabel}>
                  Configure
                </Text>
              </View>
            </TouchableOpacity>
          </View>

          <View style={styles.buttonContentContainer}>
            <TouchableOpacity
              style={styles.button}
              onPress={(): void => onRefreshScreenPressed?.()}
            >
              <View style={styles.buttonLabelContainer}>
                <Text style={styles.buttonLabel}>
                  Refresh
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  ));
}
