import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-native';
import { Image } from 'expo-image';
import { isEmpty, isUndefined } from 'lodash';
import ParentViewController from '../Shared/ParentViewController';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import { BaseModalType, modalController } from '../Shared/Modals/ModalController';
import { Colors } from '../../Config/Colors';
import Fonts from '../../Domain/Types/Fonts';
import AreaResponse from '../../Domain/Models/Responses/AreaResponse';
import { areaRepository } from '../../Domain/Repositories/AreaRepository';
import AreaListItem from './components/AreaListItem';
import CreateAreaModal from '../Shared/Modals/CreateAreaModal/CreateAreaModal';
import ModalType from '../../Domain/Types/ModalType';
import AreaScreenListItem from './components/AreaScreenListItem';
import useNavigation from '../../Domain/Hooks/useNavigation';
import Icons from '../../Assets/Icons';
import CreateAreaModalData from '../../Domain/Models/CreateAreaModalData';
import Utils from '../../Domain/Utils/Utils';
import IConfirmationDialogModel from '../../Domain/Models/IConfirmationDialogModel';
import RefreshScreenRequest from '../../Domain/Models/Requests/RefreshScreenRequest';
import ScreenService from '../../Domain/Services/ScreenService';
import INotificationModalData from '../../Domain/Models/INotificationModalData';
import EditAreaModal from '../Shared/Modals/EditAreaModal/EditAreaModal';
import EditAreaModalData from '../../Domain/Models/EditAreaModalData';
import ManageScreenPartyModal from '../Shared/Modals/ManageScreenPartyModal/ManageScreenPartyModal';
import ManageScreenPartyModalData from '../../Domain/Models/ManageScreenPartyModalData';
import PartyService from '../../Domain/Services/PartyService';

interface VenueAreasScreenProp
{
  areaId: string;
}

/**
 * Renders the Venue Area screen.
 */
export default function VenueAreasScreen(props: RouteComponentProps<{},
  never, VenueAreasScreenProp>): JSX.Element
{
  const { navigateToScreen } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [selectedArea, setSelectedArea] = useState('');
  const flatListRef = useRef<FlatList>(null);

  useEffect(() =>
  {
    if (loading)
    {
      return;
    }

    const { location } = props;
    if (location.state === undefined)
    {
      setSelectedArea('30a3d4b9-8c6f-4a24-897c-557bf005049d');
      return;
    }

    const { areaId } = location.state;

    // Double check if it still exists.
    const area = areaRepository.getById(areaId);

    if (area === undefined)
    {
      setSelectedArea('30a3d4b9-8c6f-4a24-897c-557bf005049d');
      return;
    }

    setSelectedArea(areaId);
  }, [loading]);

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    areaContainer: {
      height: RFValue(40),
      width: '93%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    areaListContainer: {
      flex: 1,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    createAreaContainer: {
      width: undefined,
      height: '100%',
      aspectRatio: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    createAreaContentContainer: {
      height: RFValue(32),
      aspectRatio: 1,
      backgroundColor: Colors.BLACK(0.4),
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.teal,
    },
    createAreaButton: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    icon: {
      width: '55%',
      aspectRatio: 1,
    },
    areaFlatList: {
      width: '100%',
      height: '100%',
    },
    areaContentFlatList: {
      justifyContent: 'center',
      alignItems: 'center',
    },
    noDataAvailableLabel: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.teal,
      textAlign: 'center',
      letterSpacing: RFValue(0.8),
    },
    partyAreaContainer: {
      width: '100%',
      height: RFValue(60),
      justifyContent: 'center',
      alignItems: 'center',
    },
    partyContentContainer: {
      width: '93%',
      height: '100%',
    },
    areaScreensContainer: {
      flex: 8,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    listContainer: {
      width: '100%',
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    listView: {
      width: '100%',
      flex: 3,
      justifyContent: 'center',
      alignItems: 'center',
    },
    screensFlatList: {
      width: '100%',
      height: '100%',
    },
  });

  const onAreaSelected = (id: string): void =>
  {
    setSelectedArea(id);
  };

  const onEditAreaPressed = async (areaId: string): Promise<void> =>
  {
    if (isEmpty(areaId))
    {
      modalController.show({
        modalType: BaseModalType.SimpleNotification,
        data: {
          title: 'Error!',
          message: 'No area selected.',
          disableCloseOnConfirm: true,
          onConfirmButtonPressed: (): void => modalController.hide(),
        },
      });
      return;
    }

    modalController.show({
      modalType: BaseModalType.NonDismissableNotification,
      data: {
        title: 'Info',
        message: 'Retrieving parties...',
      },
    });

    const response = await PartyService.getAll();
    modalController.hide();

    if (!response.success || isUndefined(response.data))
    {
      modalController.show({
        modalType: BaseModalType.SimpleNotification,
        data: {
          title: 'Error!',
          message: `Unable to grab available parties! ${response.reason}`,
          disableCloseOnConfirm: true,
          onConfirmButtonPressed: (): void => modalController.hide(),
        },
      });

      return;
    }

    const modalData: EditAreaModalData = {
      areaId,
    };

    modalController.show({
      modalType: ModalType.EditAreaModal,
      data: modalData,
    });
  };

  const getListContent = (): JSX.Element =>
  {
    const areas = areaRepository.getAll();

    if (loading)
    {
      return (
        <ActivityIndicator
          color={Colors.APP.teal}
          size="large"
        />
      );
    }

    if (areas.length !== 0)
    {
      return (
        <FlatList<AreaResponse>
          ref={flatListRef}
          style={styles.areaFlatList}
          contentContainerStyle={styles.areaContentFlatList}
          data={areas}
          renderItem={({ item }): JSX.Element => (
            <AreaListItem
              areaId={item.id}
              onAreaSelected={(): void => onAreaSelected(item.id)}
              isSelected={item.id === selectedArea}
              onEditAreaSelected={(): Promise<void> => onEditAreaPressed(item.id)}
            />
          )}
          keyExtractor={(item): string => JSON.stringify(item)}
          horizontal
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
        />
      );
    }

    return (
      <Text style={styles.noDataAvailableLabel}>
        No Areas Available.
      </Text>
    );
  };

  const onConfigureScreenPressed = (screenId: string): void =>
  {
    navigateToScreen(ScreenTypes.ScreenConfiguration, {
      screenId,
    });
  };

  const renderAreaScreens = (): JSX.Element =>
  {
    const area = areaRepository.getById(selectedArea);
    if (loading)
    {
      return (
        <ActivityIndicator color={Colors.APP.teal} size="large" />
      );
    }

    if (selectedArea.trim() === '' || area === undefined)
    {
      return (
        <Text style={styles.noDataAvailableLabel}>
          Select An Area To Begin
        </Text>
      );
    }

    if (area.screens.length === 0)
    {
      return (
        <Text style={styles.noDataAvailableLabel}>
          No Screens Assigned
        </Text>
      );
    }

    const screenIdsInArea = area.screens.map((x) => x.id);

    return (
      <View style={styles.listContainer}>
        <View style={styles.listView}>
          <FlatList<string>
            style={styles.screensFlatList}
            data={screenIdsInArea}
            renderItem={({ item, index }): JSX.Element => (
              <AreaScreenListItem
                screenId={item}
                onControlAreaScreenPressed={(): void => onControlAreaScreenPressed(item)}
                onRefreshScreenPressed={(): void => onRefreshScreenPressed(item)}
                onConfigureScreenPressed={(): void => onConfigureScreenPressed(item)}
                onSetPartyPressed={(): Promise<void> => onSetScreenPartyPressed(item)}
                index={index}
              />
            )}
            keyExtractor={(item): string => JSON.stringify(item)}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
          />
        </View>
      </View>

    );
  };

  const onSetScreenPartyPressed = async (screenId: string): Promise<void> =>
  {
    if (isEmpty(screenId))
    {
      modalController.show({
        modalType: BaseModalType.SimpleNotification,
        data: {
          title: 'Error!',
          message: 'No screen selected.',
          disableCloseOnConfirm: true,
          onConfirmButtonPressed: (): void => modalController.hide(),
        },
      });
      return;
    }

    modalController.show({
      modalType: BaseModalType.NonDismissableNotification,
      data: {
        title: 'Info',
        message: 'Retrieving parties...',
      },
    });

    const response = await PartyService.getAll();
    modalController.hide();

    if (!response.success || isUndefined(response.data))
    {
      modalController.show({
        modalType: BaseModalType.SimpleNotification,
        data: {
          title: 'Error!',
          message: `Unable to grab available parties! ${response.reason}`,
          disableCloseOnConfirm: true,
          onConfirmButtonPressed: (): void => modalController.hide(),
        },
      });

      return;
    }

    const modalData: ManageScreenPartyModalData = {
      screenId,
    };

    modalController.show({
      modalType: ModalType.ManageScreenPartyModal,
      data: modalData,
    });
  };

  const onNewAreaButtonSelected = (): void =>
  {
    const data: CreateAreaModalData = {
      onSuccess: async (): Promise<void> =>
      {
        // Arbitrary time to wait until the
        // data is populate before scrolling.
        await Utils.delay(200);
        if (flatListRef.current !== null)
        {
          flatListRef.current.scrollToEnd();
        }
      },
    };

    modalController.show({
      modalType: ModalType.CreateAreaModal,
      data,
    });
  };

  const onManageScreenSelected = (): void =>
  {
    navigateToScreen(ScreenTypes.UnassignedScreens);
  };

  const onControlAreaScreenPressed = (screenId: string): void =>
  {
    navigateToScreen(ScreenTypes.SetChannel, {
      screenId,
    });
  };

  const onRefreshScreenPressed = (screenId: string): void =>
  {
    const data: IConfirmationDialogModel = {
      message: 'Are you sure you want to\nrefresh the screen?',
      title: 'Info',
      positiveAction: async () =>
      {
        await refreshScreen(screenId);
      },
      negativeAction: (): void => modalController.hide(),
    };

    modalController.show({
      modalType: BaseModalType.GenericConfirmation,
      data,
    });
  };

  const refreshScreen = async (screenId: string): Promise<void> =>
  {
    setLoading(true);
    const request: RefreshScreenRequest = {
      screenId,
    };

    const response = await ScreenService.refreshScreen(request);
    setLoading(false);

    if (response.success)
    {
      showNotification('Screen successfully refreshed.');
      return;
    }

    showNotification(response.reason, true);
  };

  const showNotification = (message: string, error = false): void =>
  {
    const data: INotificationModalData = {
      title: error ? 'Error' : 'Info',
      message,
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data,
    });
  };

  return useObserver(() => (
    <ParentViewController
      screenType={ScreenTypes.VenueAreas}
      onSyncProgressChanged={setLoading}
      registerModals={[
        <CreateAreaModal key="CreateAreaModal" />,
        <EditAreaModal key="SetPartyModal" />,
        <ManageScreenPartyModal key="ManageScreenPartyModal" />,
      ]}
      leftButtonIcon={Icons.LogOut}
      leftButtonAction={(): void => navigateToScreen(ScreenTypes.Login)}
      onManageScreenSelected={onManageScreenSelected}
    >
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <View style={styles.areaContainer}>
            <View style={styles.areaListContainer}>
              {getListContent()}
            </View>

            <View style={styles.createAreaContainer}>
              <View style={styles.createAreaContentContainer}>
                <TouchableOpacity
                  style={styles.createAreaButton}
                  onPress={onNewAreaButtonSelected}
                >
                  <Image
                    source={Icons.NewArea}
                    contentFit="contain"
                    style={styles.icon}
                    responsivePolicy="initial"
                  />
                </TouchableOpacity>
              </View>
            </View>
          </View>

          <View style={styles.areaScreensContainer}>
            {renderAreaScreens()}
          </View>
        </View>
      </View>
    </ParentViewController>
  ));
}
