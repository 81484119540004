import React from 'react';
import {
  ImageSourcePropType,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import { Image } from 'expo-image';
import { Colors } from '../../../Config/Colors';
import Fonts from '../../../Domain/Types/Fonts';
import { channelRepository } from '../../../Domain/Repositories/ChannelRepository';
import Icons from '../../../Assets/Icons';

interface ChannelListItemProps
{
  /**
   * The item width.
   */
  itemWidth?: number;

  /**
   * The distance between each item.
   */
  itemSeparation?: number;

  /**
   * The channel id.
   */
  channelId: string;

  /**
   * Is this channel on display?
   */
  isActive?: boolean;

  /**
   * Callback when the item was pressed.
   */
  onSetChannelPressed?: () => void;
}

/**
 * Renders the Channel List item.
 */
export default function ChannelListItem({
  itemWidth = RFValue(179),
  itemSeparation = RFValue(20),
  channelId,
  isActive = false,
  onSetChannelPressed,
}: ChannelListItemProps): JSX.Element
{
  const styles = StyleSheet.create({
    container: {
      height: undefined,
      width: itemWidth,
      aspectRatio: 1.3,
      margin: itemSeparation,
      shadowColor: Colors.APP.teal,
      shadowOffset: {
        width: RFValue(2.5),
        height: RFValue(2.5),
      },
      shadowOpacity: 1,
      shadowRadius: RFValue(0.5),
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imageContentContainer: {
      flex: 0.75,
      width: '100%',
      borderWidth: RFValue(1),
      borderColor: Colors.APP.gold,
    },
    image: {
      width: '100%',
      height: '100%',
    },
    currentlyPlayingLabelContainer: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      backgroundColor: Colors.BLACK(0.7),
      justifyContent: 'center',
      alignItems: 'center',
    },
    currentlyPlayinglabel: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.gold,
      width: '90%',
      textAlign: 'center',
      lineHeight: RFValue(16),
      letterSpacing: RFValue(0.8),
    },
    labelContainer: {
      flex: 0.25,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: RFValue(1),
    },
    label: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      width: '90%',
      textAlign: 'center',
      lineHeight: RFValue(9),
      letterSpacing: RFValue(0.8),
    },
  });

  const getChannelName = (): string =>
  {
    const channel = channelRepository.getById(channelId);
    return channel === undefined ? 'Unknown Channel' : channel.name;
  };

  const getChannelImage = (): ImageSourcePropType =>
  {
    const channel = channelRepository.getById(channelId);

    if (channel === undefined || channel.iconUrl === null || channel.iconUrl.trim() === '')
    {
      return Icons.UrbanPlayground;
    }

    if (channel.iconUrl === null || channel.iconUrl.trim() === '')
    {
      return Icons.UrbanPlayground;
    }

    return {
      uri: channel.iconUrl,
    };
  };

  return useObserver(() => (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.contentContainer}
        onPress={onSetChannelPressed}
      >
        <View style={[styles.imageContentContainer, {
          borderColor: isActive ? Colors.APP.gold : 'white',
        }]}
        >
          <Image
            source={getChannelImage()}
            contentFit="cover"
            style={styles.image}
            responsivePolicy="initial"
          />

          {isActive && (
            <View style={styles.currentlyPlayingLabelContainer}>
              <Text style={styles.currentlyPlayinglabel}>
                Active Channel
              </Text>
            </View>
          )}
        </View>

        <View style={[styles.labelContainer, {
          backgroundColor: isActive ? Colors.APP.gold : Colors.APP.darkBlue,
          borderColor: isActive ? Colors.APP.gold : 'white',
        }]}
        >
          <Text style={styles.label}>
            {getChannelName()}
          </Text>
        </View>
      </TouchableOpacity>
    </View>
  ));
}
