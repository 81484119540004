import React from 'react';
import {
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { RFPercentage, RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import { BaseModalType, modalController } from '../ModalController';
import ModalViewBase from '../ModalViewBase';
import INotificationModalData from '../../../../Domain/Models/INotificationModalData';
import { Colors } from '../../../../Config/Colors';
import Fonts from '../../../../Domain/Types/Fonts';

/**
 * Non-Dismissable Notification Modal.
 * @returns JSX.Element.
 */
export default function NonDismissableNotificationModal(): JSX.Element
{
  const getData = (): INotificationModalData => modalController
    .activeModal
    .data as INotificationModalData;

  const styles = StyleSheet.create({
    container: {
      height: RFPercentage(20),
      width: undefined,
      aspectRatio: 2,
      backgroundColor: Colors.PRIMARY(),
      shadowColor: Colors.APP.teal,
      shadowOffset: {
        width: RFValue(1.1),
        height: RFValue(1.1),
      },
      shadowOpacity: 1,
      shadowRadius: RFValue(0.5),
      zIndex: 10,
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentView: {
      flex: 1,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentText: {
      textAlign: 'center',
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      width: '80%',
      letterSpacing: RFValue(0.6),
    },
  });

  return useObserver(() => (
    <ModalViewBase
      modalType={BaseModalType.NonDismissableNotification}
      closeModalOnBackgroundTouched={false}
    >
      <View style={styles.container}>
        <View style={styles.contentView}>
          <Text style={styles.contentText}>
            {getData()?.message}
          </Text>
        </View>
      </View>
    </ModalViewBase>
  ));
}
