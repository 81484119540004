import { ThirtyOneError } from '../Error/ThirtyOneError';
import Logger from '../Logger/Logger';
import { PromiseFail, PromiseResponse, PromiseSuccess } from '../Models/PromiseResponse';
import ChannelResponse from '../Models/Responses/ChannelResponse';
import { channelRepository } from '../Repositories/ChannelRepository';
import ApiService from './ApiService';

const logger = Logger.Create('ChannelService');

/**
 * Handles channel operations.
 */
export default class ChannelService
{
  /**
   * Get the available channels.
   * @returns Promise.
   */
  public static async get(): Promise<PromiseResponse<ChannelResponse[]>>
  {
    const url = '/api/Channel';

    return ApiService.get<ChannelResponse[]>(url)
      .then((response: ChannelResponse[]) =>
      {
        channelRepository.replace(response);
        return PromiseSuccess<ChannelResponse[]>(response);
      })
      .catch((error: ThirtyOneError) =>
      {
        const message = `Failed to get channels: ${error.message}`;
        logger.error(message);

        return PromiseFail(message);
      });
  }
}
