import React, { useEffect, useMemo, useState } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import { RouteComponentProps } from 'react-router-native';
import { isEmpty, isUndefined } from 'lodash';
import ParentViewController from '../Shared/ParentViewController';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import { Colors } from '../../Config/Colors';
import Fonts from '../../Domain/Types/Fonts';
import { screenRepository } from '../../Domain/Repositories/ScreenRepository';
import useNavigation from '../../Domain/Hooks/useNavigation';
import LinkManagerModal from '../Shared/Modals/LinkManagerModal/LinkManagerModal';
import { BaseModalType, modalController } from '../Shared/Modals/ModalController';
import ModalType from '../../Domain/Types/ModalType';
import ConfigurationTextEditor from './components/ConfigurationTextEditor';
import { roleRepository } from '../../Domain/Repositories/RoleRepository';
import Roles from '../../Domain/Types/Roles';
import AssignScreenModalData from '../../Domain/Models/AssignScreenModalData';
import AssignScreenModal from '../Shared/Modals/AssignScreenAreaModal/AssignScreenAreaModal';

interface ScreenConfigurationScreenProps
{
  screenId: string;
}

/**
 * Renders the Screen Configuration Screen.
 */
export default function ScreenConfigurationScreen(props: RouteComponentProps<{},
  never, ScreenConfigurationScreenProps>): JSX.Element
{
  const { navigateToScreen } = useNavigation();
  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState('');

  useEffect(() =>
  {
    const { location } = props;
    if (isUndefined(location.state))
    {
      return;
    }

    const { screenId } = location.state;
    setScreen(screenId);
  }, []);

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentContainer: {
      width: '95%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    topBarContainer: {
      height: RFValue(40),
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    topBarContentContainer: {
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    activityIndicator: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingLeft: RFValue(6),
    },
    titleLabelContainer: {
      width: '100%',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    titleContentContainer: {
      height: RFValue(13),
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    titleLabel: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.teal,
      width: '90%',
      letterSpacing: RFValue(0.8),
      paddingLeft: RFValue(10),
    },
    linkScreenContainer: {
      flex: 1,
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    linkScreenContentContainer: {
      height: '100%',
    },
    button: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    buttonLabelContainer: {
      height: RFValue(16),
      justifyContent: 'center',
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.teal,
    },
    buttonLabel: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      letterSpacing: RFValue(0.8),
      paddingLeft: RFValue(6),
      paddingRight: RFValue(10),
    },
    configurationContainer: {
      flex: 8,
      width: '99%',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    configurationContentContainer: {
      height: '97%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    configurationContentTitleContainer: {
      width: '100%',
      height: RFValue(20),
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    configurationContentTitle: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.teal,
      width: '100%',
      letterSpacing: RFValue(0.8),
      paddingLeft: RFValue(6),
    },
    textEditorContainer: {
      width: '100%',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    noDataAvailableLabel: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.teal,
      textAlign: 'center',
      letterSpacing: RFValue(0.8),
    },
    redirectButton: {
      height: RFValue(25),
      width: RFValue(90),
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Colors.APP.teal,
      marginTop: '2%',
    },
    redirectButtonLabel: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      textAlign: 'center',
      letterSpacing: RFValue(0.8),
    },
  });

  const getTitleContent = (): JSX.Element =>
  {
    if (loading)
    {
      return (
        <View style={styles.activityIndicator}>
          <ActivityIndicator color={Colors.APP.teal} size="large" />
        </View>
      );
    }

    if (isEmpty(screen))
    {
      return (
        <View style={[styles.titleContentContainer, {
          opacity: 0.5,
        }]}
        >
          <Text style={styles.titleLabel}>
            No screen selected
          </Text>
        </View>
      );
    }

    const repoScreen = screenRepository.getById(screen);
    const title = repoScreen?.name ?? '';

    return (
      <View style={styles.titleContentContainer}>
        <Text style={styles.titleLabel}>
          {title}
        </Text>
      </View>
    );
  };

  const onBackButtonPressed = (): void =>
  {
    const repoScreen = screenRepository.getById(screen);
    if (isUndefined(repoScreen))
    {
      navigateToScreen(ScreenTypes.VenueAreas, {
        areaId: '',
      });
      return;
    }

    navigateToScreen(ScreenTypes.VenueAreas, {
      areaId: repoScreen.areaId,
    });
  };

  const onLinkManagerPressed = (): void =>
  {
    if (isEmpty(screen))
    {
      modalController.show({
        modalType: BaseModalType.SimpleNotification,
        data: {
          title: 'Error!',
          message: 'No screen selected.',
          disableCloseOnConfirm: true,
          onConfirmButtonPressed: (): void => modalController.hide(),
        },
      });
      return;
    }

    modalController.show({
      modalType: ModalType.LinkManagerModal,
      data: {
        screenId: screen,
      },
    });
  };

  const renderCenterContent = useMemo(() =>
  {
    if (isEmpty(screen))
    {
      return (
        <>
          <Text style={styles.noDataAvailableLabel}>
            No Screen Selected
          </Text>

          <TouchableOpacity
            style={styles.redirectButton}
            onPress={(): void => navigateToScreen(ScreenTypes.VenueAreas)}
          >
            <Text style={styles.redirectButtonLabel}>
              Select
            </Text>
          </TouchableOpacity>
        </>
      );
    }

    if (roleRepository.role !== Roles.Admin)
    {
      return (
        <>
          <Text style={styles.noDataAvailableLabel}>
            {'You do not have sufficient privileges\nto configure this section.'}
          </Text>
        </>
      );
    }

    return (
      <ConfigurationTextEditor
        screenId={screen}
      />
    );
  }, [screen, roleRepository.role]);

  const onChangeAreaPressed = (): void =>
  {
    if (isEmpty(screen))
    {
      modalController.show({
        modalType: BaseModalType.SimpleNotification,
        data: {
          title: 'Error!',
          message: 'No screen selected.',
          disableCloseOnConfirm: true,
          onConfirmButtonPressed: (): void => modalController.hide(),
        },
      });
      return;
    }

    const modalData: AssignScreenModalData = {
      screenId: screen,
    };

    modalController.show({
      modalType: ModalType.AssignScreenAreaModal,
      data: modalData,
    });
  };

  return useObserver(() => (
    <ParentViewController
      screenType={ScreenTypes.ScreenConfiguration}
      onSyncProgressChanged={setLoading}
      leftButtonAction={onBackButtonPressed}
      registerModals={[
        <LinkManagerModal key="LinkManagerModal" />,
        <AssignScreenModal key="AssignScreenModal" />,
      ]}
    >
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <View style={styles.topBarContainer}>
            <View style={styles.topBarContentContainer}>
              <View style={[styles.titleLabelContainer]}>
                {getTitleContent()}
              </View>

              <View style={[styles.linkScreenContainer]}>
                <View style={styles.linkScreenContentContainer}>
                  <TouchableOpacity
                    style={[styles.button, {
                      opacity: isEmpty(screen) ? 0.5 : 1,
                    }]}
                    disabled={isEmpty(screen)}
                    onPress={onLinkManagerPressed}
                  >
                    <View style={styles.buttonLabelContainer}>
                      <Text style={styles.buttonLabel}>
                        Link Manager
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>

                <View style={styles.linkScreenContentContainer}>
                  <TouchableOpacity
                    style={[styles.button, {
                      opacity: isEmpty(screen) ? 0.5 : 1,
                    }]}
                    disabled={isEmpty(screen)}
                    onPress={onChangeAreaPressed}
                  >
                    <View style={styles.buttonLabelContainer}>
                      <Text style={styles.buttonLabel}>
                        Change Area
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          </View>

          <View style={styles.configurationContainer}>
            <View style={styles.configurationContentContainer}>
              <View style={styles.configurationContentTitleContainer}>
                <Text style={styles.configurationContentTitle}>
                  Channel Configuration
                </Text>
              </View>

              <View style={styles.textEditorContainer}>
                {renderCenterContent}
              </View>
            </View>
          </View>
        </View>
      </View>
    </ParentViewController>
  ));
}
