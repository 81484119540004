import React, { useState } from 'react';
import {
  ActivityIndicator,
  FlatList,
  StyleSheet, Text, View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import ParentViewController from '../Shared/ParentViewController';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import Fonts from '../../Domain/Types/Fonts';
import UnassignedScreensListItem from './components/UnassignedScreensListItem';
import { screenRepository } from '../../Domain/Repositories/ScreenRepository';
import ScreenResponse from '../../Domain/Models/Responses/ScreenResponse';
import { Colors } from '../../Config/Colors';
import AssignScreenModal from '../Shared/Modals/AssignScreenAreaModal/AssignScreenAreaModal';
import { modalController } from '../Shared/Modals/ModalController';
import ModalType from '../../Domain/Types/ModalType';
import AssignScreenModalData from '../../Domain/Models/AssignScreenModalData';

/**
 * Renders the Unassigned Screens List screen.
 */
export default function UnassignedScreensListScreen(): JSX.Element
{
  const [loading, setLoading] = useState(false);

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentContainer: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    listContainer: {
      flex: 1,
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    listView: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    flatList: {
      width: '100%',
      height: '100%',
    },
    noDataAvailableLabel: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.teal,
      textAlign: 'center',
      letterSpacing: RFValue(0.8),
    },
  });

  const onEditScreenAreaSelected = (screenId: string): void =>
  {
    const modalData: AssignScreenModalData = {
      screenId,
    };

    modalController.show({
      modalType: ModalType.AssignScreenAreaModal,
      data: modalData,
    });
  };

  const getContent = (): JSX.Element =>
  {
    const screens = screenRepository.getAll().filter((x) => x.areaId === null);

    if (loading)
    {
      return (
        <ActivityIndicator color={Colors.APP.teal} size="large" />
      );
    }

    if (screens.length !== 0)
    {
      return (
        <FlatList<ScreenResponse>
          style={styles.flatList}
          data={screens}
          renderItem={({ item, index }): JSX.Element => (
            <UnassignedScreensListItem
              screenId={item.id}
              onSelectAreaPressed={onEditScreenAreaSelected}
              index={index}
            />
          )}
          keyExtractor={(item): string => JSON.stringify(item)}
        />
      );
    }

    return (
      <Text style={styles.noDataAvailableLabel}>
        No Screens Available
      </Text>
    );
  };

  return useObserver(() => (
    <ParentViewController
      screenType={ScreenTypes.UnassignedScreens}
      onSyncProgressChanged={setLoading}
      registerModals={[
        <AssignScreenModal key="assignscreenmodal" />,
      ]}
    >
      <View style={styles.container}>
        <View style={styles.contentContainer}>
          <View style={styles.listContainer}>
            <View style={styles.listView}>
              {getContent()}
            </View>
          </View>
        </View>
      </View>
    </ParentViewController>
  ));
}
