/* eslint-disable class-methods-use-this */
import { decorate, observable } from 'mobx';
import ScreenResponse from '../Models/Responses/ScreenResponse';

/**
 * Screens Repository.
 */
export class ScreenRepository
{
  /**
   * The available screens.
   */
  public all: ScreenResponse[] = [];

  /**
   * Get the available areas.
   * @returns Area Response.
   */
  public getAll(): ScreenResponse[]
  {
    return this
      .all
      .slice()
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  }

  /**
   * Replaces all the screens.
   * @param all The screens.
   */
  public replace(all: ScreenResponse[]): void
  {
    this.all = all;
  }

  /**
   * Adds or updates the repository.
   * @param screen The screen to add or update.
   */
  public addOrUpdate(screen: ScreenResponse): void
  {
    const index = this.all.findIndex((x) => x.id === screen.id);

    if (index === -1)
    {
      this.all.push(screen);
      return;
    }

    this.all[index] = screen;
  }

  /**
   * Remove.
   * @param id The id.
   */
  public async remove(id: string): Promise<void>
  {
    const clone = [...this.all];
    const filtered = clone.filter((x) => x.id !== id);
    this.all = filtered;
  }

  /**
   * Get the screen by id.
   * @param id The id.
   * @returns The screen response.
   */
  public getById(id: string): ScreenResponse | undefined
  {
    return this.all.find((x) => x.id === id);
  }

  /**
   * Clear all.
   */
  public clearAll(): void
  {
    this.all.length = 0;
  }
}

decorate(ScreenRepository, {
  all: observable,
});

export const screenRepository = new ScreenRepository();
