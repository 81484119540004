import React, { useLayoutEffect } from 'react';
import {
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
} from 'react-native';
import * as RouterWeb from 'react-router-dom';
import * as RouterNative from 'react-router-native';
import { useObserver } from 'mobx-react-lite';
import ScreenTypes from './src/Domain/Types/ScreenTypes';
import LoginScreen from './src/Components/LoginScreen/LoginScreen';
import { getEnvironment, getOidConfig } from './src/Config/Environment';
import { responsiveScreenRepository } from './src/Domain/Repositories/ResponsiveScreenRepository';
import { appRepository } from './src/Domain/Repositories/AppRepository';
import { roleRepository } from './src/Domain/Repositories/RoleRepository';
import { userRepository } from './src/Domain/Repositories/UserRepository';
import Logger from './src/Domain/Logger/Logger';
import UnassignedScreensListScreen from './src/Components/UnassignedScreensListScreen/UnassignedScreensListScreen';
import VenueAreasScreen from './src/Components/VenueAreasScreen/VenueAreasScreen';
import SetChannelScreen from './src/Components/SetChannelScreen/SetChannelScreen';
import EditChannelScreen from './src/Components/EditChannelScreen/EditChannelScreen';
import ScreenConfigurationScreen from './src/Components/ScreenConfigureScreen/ScreenConfigureScreen';

const logger = Logger.Create('App');
const WindowDimensions = Dimensions.get('window');

/**
 * Platform specific imports.
 */
const Router = Platform.OS === 'web'
  ? RouterWeb.HashRouter
  : (RouterNative.NativeRouter as React.ElementType);
const Route = Platform.OS === 'web' ? RouterWeb.Route : RouterNative.Route;
const Switch = Platform.OS === 'web' ? RouterWeb.Switch : RouterNative.Switch;

/**
 * Top level implementation for handling the application navigation.
 * @returns JSX Element.
 */
export default function Root(): JSX.Element
{
  /**
   * Returns the navigation path which is platform dependant.
   * @param target Target screen to navigate to.
   * @returns Platform dependant absolute path
   */
  const getNavigationPath = (target: string): string => `/${target}`;

  useLayoutEffect(() =>
  {
    (async (): Promise<void> =>
    {
      responsiveScreenRepository.loaded = false;
      logger.info('Initialising the application...');

      // Set the runtime parameters.
      appRepository.setRuntimeParameters(getEnvironment(), getOidConfig());
      responsiveScreenRepository.subscribe();

      /**
       * Add any application startup requirements here...
       */
      await appRepository.checkEnvironmentPlatform();
      await userRepository.retrieveFromStorage();
      await roleRepository.retrieveRoleFromStorage();

      responsiveScreenRepository.loaded = true;
    })();
  }, []);

  const onTouchDetected = (): void =>
  {
    // Reset the inactivity flag.
    appRepository.resetInactivityFlag();
  };

  return useObserver(() => (
    <ScrollView
      style={[styles.container, {
        width: responsiveScreenRepository.newWidth,
        height: responsiveScreenRepository.newHeight,
      }]}
      contentContainerStyle={styles.scrollViewContainer}
      onTouchStart={onTouchDetected}
    >
      <Router>
        <Switch>
          <Route
            exact
            path={getNavigationPath(ScreenTypes.Login)}
            component={LoginScreen}
          />
          <Route
            exact
            path={getNavigationPath(ScreenTypes.UnassignedScreens)}
            component={UnassignedScreensListScreen}
          />
          <Route
            exact
            path={getNavigationPath(ScreenTypes.VenueAreas)}
            component={VenueAreasScreen}
          />
          <Route
            exact
            path={getNavigationPath(ScreenTypes.SetChannel)}
            component={SetChannelScreen}
          />
          <Route
            exact
            path={getNavigationPath(ScreenTypes.EditChannel)}
            component={EditChannelScreen}
          />
          <Route
            exact
            path={getNavigationPath(ScreenTypes.ScreenConfiguration)}
            component={ScreenConfigurationScreen}
          />
        </Switch>
      </Router>
    </ScrollView>
  ));
}

const styles = StyleSheet.create({
  container: {
    width: WindowDimensions.width,
    height: WindowDimensions.height,
  },
  scrollViewContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
});
