import { ThirtyOneError } from '../Error/ThirtyOneError';
import Logger from '../Logger/Logger';
import { PromiseFail, PromiseResponse, PromiseSuccess } from '../Models/PromiseResponse';
import ApiService from './ApiService';

const logger = Logger.Create('SystemService');

/**
 * Handles system operations.
 */
export default class SystemService
{
  /**
   * Syncs the screens and areas in the server.
   * @returns Promise.
   */
  public static async sync(): Promise<PromiseResponse>
  {
    const url = '/api/System/Sync';

    return ApiService.post(url)
      .then(() => PromiseSuccess())
      .catch((error: ThirtyOneError) =>
      {
        const message = `Failed to sync: ${error.message}`;
        logger.error(message);

        return PromiseFail(message);
      });
  }

  /**
   * Link a screen.
   * @param screenId The screen id.
   * @param linkCode The link code.
   * @returns Promise.
   */
  public static async link(screenId: string, linkCode: string): Promise<PromiseResponse>
  {
    const url = '/api/System/Link';
    const body = {
      screenId,
      linkCode,
    };

    return ApiService.post(url, body)
      .then(() => PromiseSuccess())
      .catch((error: ThirtyOneError) =>
      {
        const { message } = error;
        logger.error(message);
        return PromiseFail(message);
      });
  }

  /**
   * Unlink a screen.
   * @param screenId The screen id.
   * @returns Promise.
   */
  public static async unlink(screenId: string): Promise<PromiseResponse>
  {
    const url = '/api/System/Unlink';
    const body = {
      screenId,
    };

    return ApiService.post(url, body)
      .then(() => PromiseSuccess())
      .catch((error: ThirtyOneError) =>
      {
        const { message } = error;
        logger.error(message);
        return PromiseFail(message);
      });
  }
}
