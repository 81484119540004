import React from 'react';
import {
  ImageSourcePropType,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import { Image } from 'expo-image';
import Fonts from '../../Domain/Types/Fonts';
import ScreenTypes from '../../Domain/Types/ScreenTypes';
import Icons from '../../Assets/Icons';
import { Colors } from '../../Config/Colors';

interface HeaderProps
{
  /**
   * The screen type.
   */
  screenType?: ScreenTypes;

  /**
   * Callback when the sync button was pressed.
   */
  onSyncButtonSelected?: () => void;

  /**
   * Callback when the refresh was pressed.
   */
  onRefreshButtonSelected?: () => void;

  /**
   * Callback when the manage screens was pressed.
   */
  onManageScreenSelected?: () => void;

  /**
   * The left button icon.
   */
  leftButtonIcon?: ImageSourcePropType;

  /**
   * Callback when the left button was pressed.
   */
  leftButtonAction?: () => void;
}

/**
 * Renders the header.
 */
export default function Header({
  screenType = ScreenTypes.Login,
  onSyncButtonSelected,
  onRefreshButtonSelected,
  onManageScreenSelected,
  leftButtonIcon = Icons.Back,
  leftButtonAction,
}: HeaderProps): JSX.Element
{
  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentContainer: {
      width: '95%',
      height: '95%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    topContainer: {
      width: '100%',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    leftButtonContainer: {
      width: undefined,
      aspectRatio: 1,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    leftButton: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingLeft: RFValue(10),
    },
    leftButtonIcon: {
      width: RFValue(11),
      height: undefined,
      aspectRatio: 1,
    },
    titleContainer: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      width: '100%',
      textAlign: 'left',
      letterSpacing: RFValue(0.8),
      paddingRight: RFValue(10),
    },
    bottomContainer: {
      width: '100%',
      flex: 1,
    },
    buttonContainer: {
      flex: 1,
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      flexDirection: 'row',
    },
    buttonContentContainer: {
      height: '100%',
    },
    button: {
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    buttonLabelContainer: {
      height: RFValue(16),
      justifyContent: 'center',
      borderLeftWidth: RFValue(1),
      borderLeftColor: Colors.APP.teal,
    },
    buttonLabel: {
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      letterSpacing: RFValue(0.8),
      paddingLeft: RFValue(6),
      paddingRight: RFValue(10),
    },
  });

  const getHeaderTitle = (): string =>
  {
    switch (screenType)
    {
      case ScreenTypes.UnassignedScreens:
        return 'Unassigned Screens';
      case ScreenTypes.VenueAreas:
        return 'Venue Areas';
      case ScreenTypes.SetChannel:
        return 'Set Channel';
      case ScreenTypes.EditChannel:
        return 'Edit Channel';
      case ScreenTypes.ScreenConfiguration:
        return 'Screen Configuration';
      default:
        return '';
    }
  };

  return useObserver(() => (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        <View style={styles.topContainer}>
          <View style={styles.leftButtonContainer}>
            <TouchableOpacity
              style={styles.leftButton}
              onPress={leftButtonAction}
            >
              <Image
                source={leftButtonIcon}
                style={styles.leftButtonIcon}
                contentFit="contain"
                responsivePolicy="initial"
                contentPosition="left center"
              />
            </TouchableOpacity>
          </View>

          <View style={styles.titleContainer}>
            <Text style={styles.title}>
              {getHeaderTitle()}
            </Text>
          </View>
        </View>

        <View style={styles.bottomContainer}>
          <View style={styles.buttonContainer}>
            <View style={styles.buttonContentContainer}>
              <TouchableOpacity
                style={styles.button}
                onPress={onSyncButtonSelected}
              >
                <View style={styles.buttonLabelContainer}>
                  <Text style={styles.buttonLabel}>
                    Sync All
                  </Text>
                </View>
              </TouchableOpacity>
            </View>

            <View style={styles.buttonContentContainer}>
              <TouchableOpacity
                style={styles.button}
                onPress={onRefreshButtonSelected}
              >
                <View style={styles.buttonLabelContainer}>
                  <Text style={styles.buttonLabel}>
                    Refresh All
                  </Text>
                </View>
              </TouchableOpacity>
            </View>

            {screenType === ScreenTypes.VenueAreas && (
              <>
                <View style={styles.buttonContentContainer}>
                  <TouchableOpacity
                    style={styles.button}
                    onPress={onManageScreenSelected}
                  >
                    <View style={styles.buttonLabelContainer}>
                      <Text style={styles.buttonLabel}>
                        Manage Unassigned Screens
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </>
            )}
          </View>
        </View>
      </View>
    </View>
  ));
}
