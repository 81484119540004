import React, { useEffect, useState } from 'react';
import {
  StyleSheet, Text, View,
} from 'react-native';
import { RFValue } from 'react-native-responsive-fontsize';
import { useObserver } from 'mobx-react-lite';
import Checkbox from 'expo-checkbox';
import Fonts from '../../../Domain/Types/Fonts';
import { Colors } from '../../../Config/Colors';
import { channelRepository } from '../../../Domain/Repositories/ChannelRepository';

interface EditChannelListItemProps
{
  /**
   * The item height.
   */
  itemHeight?: number;

  /**
   * The screen id.
   */
  channelId: string;

  /**
   * The initial checkbox state
   */
  initialState?: boolean;

  /**
   * Callback when the checkbox was pressed.
   * @param id The selected id.
   */
  onCheckBoxSelected?: (state: boolean) => void;

  /**
   * Item index.
   */
  index?: number;

  /**
   * Disable the item.
   */
  disabled?: boolean;
}

/**
 * Renders the Edit Channel List Item.
 */
export default function EditChannelListItem({
  itemHeight = RFValue(45),
  channelId,
  initialState = false,
  onCheckBoxSelected,
  index = 0,
  disabled = false,
}: EditChannelListItemProps): JSX.Element
{
  const [isChecked, setChecked] = useState(false);

  useEffect(() =>
  {
    setChecked(initialState);
  }, [initialState]);

  const styles = StyleSheet.create({
    container: {
      width: '100%',
      height: itemHeight,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: index % 2 ? Colors.BLACK(0.2) : undefined,
    },
    contentContainer: {
      width: '95%',
      height: '100%',
      flexDirection: 'row',
    },
    labelContainer: {
      flex: 1,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
    label: {
      width: '100%',
      fontSize: RFValue(10),
      letterSpacing: RFValue(0.5),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      paddingLeft: RFValue(6),
    },
    screenNameLabel: {
      fontSize: RFValue(10),
      letterSpacing: RFValue(0.5),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      paddingLeft: RFValue(5),
    },
    checkboxContainer: {
      height: '100%',
      width: undefined,
      aspectRatio: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    checkbox: {
      height: '40%',
      width: undefined,
      aspectRatio: 1,
      overflow: 'hidden',
    },
  });

  const getChannelName = (): string =>
  {
    const channel = channelRepository.getById(channelId);
    return channel?.name ?? '';
  };

  const onCheckBoxPressed = (state: boolean): void =>
  {
    setChecked(state);

    if (onCheckBoxSelected !== undefined)
    {
      onCheckBoxSelected(state);
    }
  };

  return useObserver(() => (
    <View style={[styles.container, {
      opacity: disabled ? 0.5 : 1,
    }]}
    >
      <View style={styles.contentContainer}>
        <View style={styles.labelContainer}>
          <Text style={styles.label}>
            {`${index + 1}.`}
            <Text style={styles.screenNameLabel}>
              {getChannelName()}
            </Text>
          </Text>
        </View>
        <View style={styles.checkboxContainer}>
          <Checkbox
            style={styles.checkbox}
            value={isChecked}
            onValueChange={onCheckBoxPressed}
            color={isChecked ? Colors.APP.teal : Colors.APP.teal}
            disabled={disabled}
          />
        </View>
      </View>
    </View>
  ));
}
