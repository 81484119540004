import React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import { RFPercentage, RFValue } from 'react-native-responsive-fontsize';
import { LinearGradient } from 'expo-linear-gradient';
import { modalController } from '../ModalController';
import { Colors } from '../../../../Config/Colors';
import Fonts from '../../../../Domain/Types/Fonts';

export interface ConfirmationDialogModalBaseProps
{
  /**
   * Title.
   */
  title?: string;

  /**
   * Message.
   */
  message?: string;

  /**
   * The error message.
   */
  errorMessage?: string;

  /**
   * Custom content.
   */
  content?: JSX.Element;

  /**
   * Callback when the positive button was pressed.
   */
  onPositivePressed?: () => void;

  /**
   * Callback when the negative button was pressed.
   */
  onNegativePressed?: () => void;
}

/**
 * Confirmation Dialog Modal Base.
 * @returns JSX.Element.
 */
export default function ConfirmationDialogModalBase({
  title = 'Caution!',
  content = <></>,
  message,
  errorMessage = '',
  onPositivePressed,
  onNegativePressed,
}: ConfirmationDialogModalBaseProps): JSX.Element
{
  const getContent = (): JSX.Element =>
  {
    if (message === undefined || message?.trim() === '')
    {
      return content;
    }

    return (
      <Text style={styles.contentText}>
        {message}
      </Text>
    );
  };

  const onPositiveButtonPressed = (): void =>
  {
    modalController.genericConfirmationModalError = '';

    if (onPositivePressed !== undefined)
    {
      onPositivePressed();
    }
  };

  const styles = StyleSheet.create({
    container: {
      height: RFPercentage(20),
      width: undefined,
      aspectRatio: 2,
      backgroundColor: Colors.PRIMARY(),
      shadowColor: Colors.APP.teal,
      shadowOffset: {
        width: RFValue(1.1),
        height: RFValue(1.1),
      },
      shadowOpacity: 1,
      shadowRadius: RFValue(0.5),
      zIndex: 10,
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'flex-end',
    },
    titleText: {
      textAlign: 'center',
      fontSize: RFValue(10),
      letterSpacing: RFValue(0.6),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.teal,
    },
    contentView: {
      flex: 0.55,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    contentText: {
      textAlign: 'center',
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
      width: '80%',
      letterSpacing: RFValue(0.6),
    },
    decisionButtonView: {
      flex: 0.15,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      width: '45%',
    },
    decisionButtonContent: {
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    decisionButton: {
      width: '45%',
      height: '75%',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      marginTop: '1.5%',
      shadowColor: 'black',
      shadowOffset: {
        width: RFValue(1.1),
        height: RFValue(1.1),
      },
      shadowOpacity: 0.4,
      shadowRadius: 20,
    },
    decisionButtonText: {
      textAlign: 'center',
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: 'white',
    },
    errorMessageView: {
      flex: 0.1,
      width: '100%',
      justifyContent: 'flex-start',
    },
    errorText: {
      textAlign: 'center',
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      color: Colors.APP.red,
    },
  });

  return (
    <View style={styles.container}>
      <View style={styles.titleView}>
        <Text style={styles.titleText}>
          {title}
        </Text>
      </View>

      <View style={styles.contentView}>
        {getContent()}
      </View>

      <View style={styles.decisionButtonView}>
        <TouchableOpacity
          onPress={onPositiveButtonPressed}
          style={styles.decisionButton}
        >
          <LinearGradient
            colors={Colors.LOGIN().active}
            style={styles.decisionButtonContent}
          >
            <Text style={styles.decisionButtonText}>
              Yes
            </Text>
          </LinearGradient>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={onNegativePressed}
          style={styles.decisionButton}
        >
          <LinearGradient
            colors={Colors.LOGIN().active}
            style={styles.decisionButtonContent}
          >
            <Text style={styles.decisionButtonText}>
              No
            </Text>
          </LinearGradient>
        </TouchableOpacity>
      </View>

      <View style={styles.errorMessageView}>
        <Text style={styles.errorText}>
          {errorMessage}
        </Text>
      </View>
      <View />
    </View>
  );
}
