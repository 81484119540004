/* eslint-disable class-methods-use-this */
import { decorate, observable } from 'mobx';
import AreaResponse from '../Models/Responses/AreaResponse';

/**
 * Area Repository.
 */
export class AreaRepository
{
  /**
   * The available area.
   */
  public all: AreaResponse[] = [];

  /**
   * Get the available areas.
   * @returns Area Response.
   */
  public getAll(): AreaResponse[]
  {
    return this
      .all
      .slice()
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  }

  /**
   * Replaces all the area.
   * @param all The area.
   */
  public replace(all: AreaResponse[]): void
  {
    this.all = all;
  }

  /**
   * Adds or updates the repository.
   * @param area The area to add or update.
   */
  public addOrUpdate(area: AreaResponse): void
  {
    const index = this.all.findIndex((x) => x.id === area.id);

    if (index === -1)
    {
      this.all.push(area);
      return;
    }

    this.all[index] = area;
  }

  /**
   * Remove.
   * @param id The id.
   */
  public async remove(id: string): Promise<void>
  {
    const clone = [...this.all];
    const filtered = clone.filter((x) => x.id !== id);
    this.all = filtered;
  }

  /**
   * Get the area by id.
   * @param id The id.
   * @returns The area response.
   */
  public getById(id: string): AreaResponse | undefined
  {
    return this.all.find((x) => x.id === id);
  }

  /**
   * Clear all.
   */
  public clearAll(): void
  {
    this.all.length = 0;
  }
}

decorate(AreaRepository, {
  all: observable,
});

export const areaRepository = new AreaRepository();
