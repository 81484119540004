import React, { useMemo, useState } from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native';
import { useObserver } from 'mobx-react-lite';
import { RFPercentage, RFValue } from 'react-native-responsive-fontsize';
import { LinearGradient } from 'expo-linear-gradient';
import { isEmpty, isNull, isUndefined } from 'lodash';
import Fonts from '../../../../Domain/Types/Fonts';
import { responsiveScreenRepository } from '../../../../Domain/Repositories/ResponsiveScreenRepository';
import ModalViewBase from '../ModalViewBase';
import ModalType from '../../../../Domain/Types/ModalType';
import { Colors } from '../../../../Config/Colors';
import { BaseModalType, modalController } from '../ModalController';
import INotificationModalData from '../../../../Domain/Models/INotificationModalData';
import LinkManagerModalData from '../../../../Domain/Models/LinkManagerModalData';
import IConfirmationDialogModel from '../../../../Domain/Models/IConfirmationDialogModel';
import SystemService from '../../../../Domain/Services/SystemService';

const LinkButtonColor = [Colors.APP.green, Colors.APP.green];
const UnlinkButtonColor = [Colors.APP.red, Colors.APP.red];

/**
 * The Link Manager Modal.
 * @returns JSX.Element.
 */
export default function LinkManagerModal(): JSX.Element
{
  const [linkCode, setLinkCode] = useState('');
  const [loading, setLoading] = useState(false);

  const closeModal = (): void =>
  {
    modalController.hide();
  };

  const onLinkSelected = async (): Promise<void> =>
  {
    const data = modalController.activeModal.data as LinkManagerModalData;
    const { screenId } = data;

    if (isUndefined(data) || isUndefined(screenId) || isNull(screenId) || isEmpty(screenId))
    {
      showNotification('No screen selected');
      return;
    }

    if (isEmpty(linkCode))
    {
      showNotification('You must provide a link code to proceed!');
      return;
    }

    const notification: IConfirmationDialogModel = {
      title: 'Info',
      message: 'Are you sure you want to link this screen?',
      negativeAction: (): void => modalController.showPrevious(),
      positiveAction: async (): Promise<void> =>
      {
        const response = await SystemService.link(screenId, linkCode);
        if (response.success)
        {
          showNotification('Successfully sent link code.', 'Info', (): void => modalController.hide());
          return;
        }

        showNotification(response.reason);
      },
    };

    modalController.show({
      modalType: BaseModalType.GenericConfirmation,
      data: notification,
    });
  };

  const onUnlinkSelected = async (): Promise<void> =>
  {
    const data = modalController.activeModal.data as LinkManagerModalData;
    const { screenId } = data;

    if (isUndefined(data) || isUndefined(screenId) || isNull(screenId) || isEmpty(screenId))
    {
      showNotification('No screen selected');
      return;
    }

    const notification: IConfirmationDialogModel = {
      title: 'Info',
      message: 'Are you sure you want to unlink this screen?',
      negativeAction: (): void => modalController.showPrevious(),
      positiveAction: async (): Promise<void> =>
      {
        const response = await SystemService.unlink(screenId);
        if (response.success)
        {
          showNotification('Successfully sent unlink request!', 'Info', (): void => modalController.hide());
          return;
        }

        showNotification(response.reason);
      },
    };

    modalController.show({
      modalType: BaseModalType.GenericConfirmation,
      data: notification,
    });
  };

  const showNotification = (
    reason: string,
    title = 'Error!',
    onConfirmed = (): void => modalController.showPrevious(),
  ): void =>
  {
    const notification: INotificationModalData = {
      title,
      message: reason,
      disableCloseOnConfirm: true,
      onConfirmButtonPressed: onConfirmed,
    };

    modalController.show({
      modalType: BaseModalType.SimpleNotification,
      data: notification,
    });
  };

  const reset = (): void =>
  {
    setLinkCode('');
    setLoading(false);
  };

  const styles = useMemo(() => StyleSheet.create({
    content: {
      height: RFPercentage(24),
      width: undefined,
      aspectRatio: 1.8,
      backgroundColor: Colors.PRIMARY(),
      shadowColor: Colors.APP.teal,
      shadowOffset: {
        width: RFValue(1.1),
        height: RFValue(1.1),
      },
      shadowOpacity: 1,
      shadowRadius: RFValue(0.5),
      zIndex: 10,
    },
    container: {
      height: '100%',
      width: '100%',
    },
    titleView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    titleText: {
      textAlign: 'left',
      fontSize: RFValue(10),
      letterSpacing: RFValue(0.6),
      color: 'white',
      fontFamily: Fonts.JudgeMedium,
    },
    nameInputView: {
      flex: 0.6,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    input: {
      height: '30%',
      width: '50%',
      backgroundColor: Colors.BLACK(0.15),
      color: 'white',
      paddingHorizontal: '5%',
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      textAlign: 'center',
    },
    message: {
      color: Colors.APP.red,
      fontSize: RFValue(10),
      fontFamily: Fonts.JudgeMedium,
      textAlign: 'center',
      paddingTop: '1%',
    },
    closeView: {
      flex: 0.2,
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row-reverse',
    },
    button: {
      height: '70%',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '5%',
      marginHorizontal: '2%',
    },
    buttonContent: {
      width: '100%',
      height: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonTextView: {
      width: '100%',
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonText: {
      paddingHorizontal: RFValue(20),
      textAlign: 'center',
      fontFamily: Fonts.JudgeMedium,
      fontSize: RFValue(10),
      color: 'white',
    },
  }), [responsiveScreenRepository.key]);

  return useObserver(() => (
    <ModalViewBase
      onModalHidden={reset}
      modalType={ModalType.LinkManagerModal}
      closeModalOnBackgroundTouched={false}
    >
      <View style={styles.content}>
        <View style={styles.container}>
          <View style={styles.titleView}>
            <Text style={styles.titleText}>
              Link Manager
            </Text>
          </View>

          <View style={styles.nameInputView}>
            <TextInput
              style={styles.input}
              keyboardType="email-address"
              placeholder="Enter Link Code"
              autoCompleteType="off"
              placeholderTextColor="white"
              onChangeText={setLinkCode}
              value={linkCode}
            />
          </View>

          {!loading ? (
            <View style={styles.closeView}>
              <TouchableOpacity
                style={styles.button}
                disabled={isEmpty(linkCode)}
                onPress={onLinkSelected}
              >
                <LinearGradient
                  colors={isEmpty(linkCode) ? Colors.LOGIN().inactive : LinkButtonColor}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Link
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button}
                onPress={onUnlinkSelected}
              >
                <LinearGradient
                  colors={UnlinkButtonColor}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Unlink
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>

              <TouchableOpacity
                style={styles.button}
                onPress={closeModal}
              >
                <LinearGradient
                  colors={Colors.LOGIN().active}
                  style={styles.buttonContent}
                >
                  <View style={styles.buttonTextView}>
                    <Text style={styles.buttonText}>
                      Cancel
                    </Text>
                  </View>
                </LinearGradient>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={styles.closeView}>
              <ActivityIndicator color={Colors.APP.teal} size="large" />
            </View>
          )}
        </View>
      </View>
    </ModalViewBase>
  ));
}
